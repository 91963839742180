import FormValidator from "./../../shared/utils/FormValidator.js";
import FormDefaultHandling from "./../modules/FormDefaultHandling.js";

export default class ContactPage {

  constructor() {
    this._formSelector = "form#contact-form";
    this._formLoaderSelector = "#contact-form-loader";
    this._formValidation = {
      name : {
        selector : "input[name=name]",
        regex : new RegExp("^.+")
      },
      email : {
        selector : "input[name=email]",
        regex : new RegExp("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z\.]{2,6}$")
      },
      message : {
        selector : "textarea[name=message]",
        regex : new RegExp("^.+")
      }
    }
  }

  init() {
    this._form = $(this._formSelector);
    this._formLoader = $(this._formLoaderSelector);
    this.bindFormValidation();   
  }

  bindFormValidation() {
    this._formDefaultHandling = new FormDefaultHandling(document.querySelector(this._formSelector));

    let _contactFormParams = {
      formSelector : this._formSelector,
      requiredFields : this._formValidation,
      ajaxed: true,
      validateOnInit: false,
      submitAction: this.getFormSubmitAction()
    };

    this._validator = new FormValidator(_contactFormParams);
  }

  showContactFormLoader() {
    this._formLoader.addClass('show');
  }

  hideContactFormLoader() {
    this._formLoader.removeClass('show');
  }

  getFormSubmitAction() {

    return () => {
      $.ajax({
        url: this._form.attr('action'),
        type: this._form.attr('method'),
        cache: false,
        async: false,
        data: $(this._form).serialize(),
        beforeSend: (xhr, settings) => {
          this.showContactFormLoader()
        },
        success: () => {
          this.hideContactFormLoader();
          this.showSuccessMessage();
          this.resetForm();
        },
        error: () => {
          this.hideContactFormLoader();
          this.showErrorMessage();
        }
      });
    }
  }

  showErrorMessage() {
    $('.feedback-message.error').slideDown(300, 'swing');
  }

  showSuccessMessage() {
    $('.feedback-message.success').slideDown(300, 'swing');
  }

  resetForm() {
    $(':input:not([type=submit])', this._form).val('')
  }

  hideFeedback() {
    $('.feedback-message').slideUp(300, 'swing');
  }

}