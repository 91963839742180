export default class MJSSideNavBar {
  
  // First process parameters passed and set all class attributes
  constructor(params) {

    params = params || {};

    // First let's set default attributes of the class
    this.str_sidebar_selector = "#mjsSideNavBar";
    this.str_nav_selector = 'nav#sidenav';
    this.str_main_header_title = 'Menu';

    this.processParameters(params);

    // Once we have the data, start making selections
    this.node_sidebar_container = document.querySelector(this.str_sidebar_selector);
    this.node_nav_container = this.node_sidebar_container.querySelector(this.str_nav_selector);
    this.node_nav = this.node_nav_container.querySelector('ul');
    this.arr_nav_levels = [];

  }

  /**
   * Method processes parameters passed to the Sidebar
   * Accepting following parameters
   * - navSelector - string, represents child element inside sidebar holding the navigation, defaults to nav
   * - mainHeaderTitle - string, title of the nav that is used for a top level menu
   */
  processParameters(params) {
    this.str_nav_selector = params.navSelector || this.str_nav_selector;
    this.str_main_header_title = params.mainHeaderTitle || this.str_main_header_title;
  }

  // Initiates the sidebar
  init() {
    
    // Add the page mask
    this.addPageMask();

    // Create header to the nav with close button
    this.addHeader();
    this.addCloseButton();
    this.addHeaderBackButton();

    // Next thing will be to put the main nav first nav with the class nextLvl
    // Rotation will be happening with previousLvl, currentLvl, nextLvl
    this.node_nav.classList.add('nextLvl');

    // Set the title of the sidebar to the default one
    this.setNavHeaderTitleFromNav(this.node_nav);

    // Move levels of the nav
    this.moveNavLevels('down'); 

    // Assign events to created sidebar
    this.assignClickEventToSubnavs();
    this.assignClickEventToNavBackBtn();
  }
  
  // Prepends mjsPageMask to the body tag
  addPageMask() {
    this.node_page_mask = document.createElement('div');
    this.node_page_mask.setAttribute('id', 'mjsPageMask');
    this.node_page_mask.addEventListener('click', () => {
      this.hide();
    });
    $('body').prepend(this.node_page_mask);
  }
  
  // Adds close button to the sidebar
  addCloseButton() {
    this.node_close_sidebar_btn = document.createElement('div');
    this.node_close_sidebar_btn.setAttribute('id', 'closeSideBarBtn');
    $(this.node_close_sidebar_btn).prepend(document.createElement('span'));
    this.node_close_sidebar_btn.addEventListener('click', () => {
      this.hide();
    }); 
    $(this.node_sidebar_container).prepend(this.node_close_sidebar_btn);
  }

  // Method creates header element and puts the default title to it
  addHeader() {
    this.node_header = document.createElement('header');
    this.node_header_title = document.createElement('div');
    this.node_header_title.classList.add('headerTitle');
    this.node_header_title.innerHTML = this.str_main_header_title;
    $(this.node_header).prepend(this.node_header_title);
    $(this.node_sidebar_container).prepend(this.node_header);
  }

  // Adds close button to the nav header
  addHeaderBackButton() {
    this.node_nav_back_btn = document.createElement('div');
    this.node_nav_back_btn.classList.add('backBtn');
    $(this.node_header).prepend(this.node_nav_back_btn);
  }

  // Shows the sidebar and pagemask
  show() {
    this.node_sidebar_container.classList.add('show');
    this.node_page_mask.classList.add('show');
  }

  // Hides the sidebar and pagemask
  hide() {
    this.node_sidebar_container.classList.remove('show');
    this.node_page_mask.classList.remove('show');
    setTimeout(() => {
      this.resetNav();
    }, 500);
  }

  // Sets the title based on the dataset or default title attribute
  setNavHeaderTitleFromNav(nav) {
    // Check if the nav contains dataset
    if (typeof nav.dataset.navTitle !== "undefined") {
      this.node_header_title.innerHTML = nav.dataset.navTitle;
    } else {
      // The last element. Fall back to the default
      this.node_header_title.innerHTML = this.str_main_header_title;
    }
  }
  
  // Adds the nav node to the arr_nav_levels array
  addToNavLevels(nav) {
    let node_cloned = nav.cloneNode(true);
    // Remove all classes that are coresponding to levels
    node_cloned.removeAttribute('class');
    this.arr_nav_levels.push(node_cloned);
  }  
  
  /**
   * Method populates the nav node that is passed as an argument to the method
   * by replacing content of the node container
   */
  populateNav(node_nav) {

    // Depending on the position we want to prepend or append
    if (node_nav.classList.contains('previousLvl')) {
      // Check if there is a previous node already
      let node_previous_lvl = this.node_nav_container.querySelector('.previousLvl');
      if (node_previous_lvl) { 
        node_previous_lvl.remove();
      }
      $(this.node_nav_container).prepend(node_nav);
    } else if(node_nav.classList.contains('nextLvl')){
      // Check if the next level exists
      let node_next_lvl = this.node_nav_container.querySelector('.nextLvl');
      if (node_next_lvl) { 
        node_next_lvl.remove();
      }
      this.node_nav_container.append(node_nav);
    } else {
      this.node_nav_container.innerHTML = '';
      this.node_nav_container.append(node_nav);
    }

    // Assign click events to all subnavs
    this.assignClickEventToSubnavs();
  }
  
  resetNav() {
    // Get the reference to the top level node and populate it
    if (this.arr_nav_levels.length >= 1) {
      this.node_nav_container.innerHTML = '';
      let node_top_level = this.arr_nav_levels[0].cloneNode(true);
      this.populateNav(node_top_level);
      this.setNavHeaderTitleFromNav(node_top_level);
      node_top_level.classList.add('currentLvl');
      this.hideNavBackBtn();
      this.arr_nav_levels = [];
    }
  }


  /**
   * Method adds click events to the nav found in the sideBar
   * It selects all subnavs (li.subnav) and adds click event
   * by calling handleSubnavClick method
   */
  assignClickEventToSubnavs() {

    // First, check if we have a nav container
    if (typeof this.node_nav_container !== 'undefined') {

      // Check for subnavs, and if there are any, add click event 
      let arr_subnavs = this.node_nav_container.querySelectorAll('ul li.subnav');

      if (arr_subnavs.length > 0) {
        
        // Iterate over subnavs and add click events to each one of them
        for (var int_index in arr_subnavs) {

          // Get current subnav node
          let node_current_subnav = arr_subnavs[int_index];

          // Check if we are dealing with the node type 1
          if (node_current_subnav.nodeType == 1) {            
            let node_cloned_subnav = node_current_subnav.cloneNode(true);
            node_current_subnav.parentNode.replaceChild(node_cloned_subnav, node_current_subnav);
            node_cloned_subnav.addEventListener(
              'click', () => {
                this.handleSubnavClick(node_cloned_subnav);
              }
            );
          }
        }
      }
    }
  }
  
  /**
   * Method handles subnav click event.
   * It gets the data-subnav-title value and passes it to the
   * setNavHeaderTitle method.
   * Calls showNavBackBtn to display back button
   * Once set, it calls populateNav method which receives subnav node
   * as an argument.
   */
  handleSubnavClick(node_subnav_li) {
    // Select the parent node and clone it to get all its childrens
    let node_subnav_parent = node_subnav_li.parentNode.cloneNode(true);
    
    // Put cloned node into the arr_nav_levels
    this.addToNavLevels(node_subnav_parent);

    // Show back button
    this.showNavBackBtn();

    // Select ul element in the subnav
    let node_subnav = node_subnav_li.querySelector('ul');

    // Add relevant class to recognise level
    node_subnav.classList.add('nextLvl');

    // Populate subnav to the nav container
    this.populateNav(node_subnav);

    // Set the title
    this.setNavHeaderTitleFromNav(node_subnav);

    setTimeout(() => {
      // Move levels down
      this.moveNavLevels('down');
    }, 10);
  }

  /**
   * This method controls levels assignement and handles edge nodes removal.
   * Method accepts parameter as a direction (up|down).
   */
  moveNavLevels(str_direction) {

    let node_current_lvl = this.node_nav_container.querySelector('.currentLvl'),
        node_next_lvl = this.node_nav_container.querySelector('.nextLvl'),
        node_previous_lvl = this.node_nav_container.querySelector('ul.previousLvl');

    // BACK BUTTON
    // With back button we 
    if (str_direction == 'up') {
      // This is support for the back button, as we will be transitioning from previous to current
      // Make sure we have a previous level
      let node_previous_lvl = this.node_nav_container.querySelector('ul.previousLvl');
      if (node_previous_lvl) {
        node_previous_lvl.classList.remove('nextLvl');
        node_previous_lvl.className = node_previous_lvl.className.replace("previousLvl", "currentLvl");
      }

      // Deal with the next level. This simply needs to be removed
      if (node_next_lvl) {
        node_next_lvl.remove();
      }

      // Current level becomes next level
      if (node_current_lvl) {
        node_current_lvl.className = node_current_lvl.className.replace("currentLvl", "nextLvl");
        setTimeout(() => {
          node_current_lvl.remove();
        }, 400);
      }

    } 

    // SUBNAV CLICK
    else if (str_direction == 'down') {
      // When subnav is clicked we will drill down into nav, so from current becomes previous and next becomes current

      // Check if we have previous level already, and if so, simply remove it
      if (node_previous_lvl) {
        node_previous_lvl.remove();
      }

      // Current level, if exists, becomes previous level
      if (node_current_lvl) {
        node_current_lvl.className = node_current_lvl.className.replace("currentLvl", "previousLvl");        
      }

      // Next level becomes current
      node_next_lvl.className = node_next_lvl.className.replace("nextLvl", "currentLvl");
    }

    else {
      console.error("Unable to move nav levels as the direction is not specified");
    }
  }

  /**
   * Shows the header .backBtn by adding show class to it
   * and adding containsBackBtn class to the header .headerTitle
   */
  showNavBackBtn() {
    this.node_nav_back_btn.classList.add('show');
    this.node_header_title.classList.add('containsBackBtn');
  }

  hideNavBackBtn() {
    this.node_nav_back_btn.classList.remove('show');
    this.node_header_title.classList.remove('containsBackBtn');
  }

  /**
   * Handles header .backBtn click event by passing parentNav to 
   * populateNav method
   */
  assignClickEventToNavBackBtn() {
    this.node_nav_back_btn.addEventListener('click', (e) => {
      this.handleBackBtnClick();
    });
  }

  // Method handles the click event on the nav back button
  handleBackBtnClick() {
    // This will be as simple as getting out the last node from the array
    let node_previous_lvl = this.arr_nav_levels.pop();

    // Mark it as previous level
    node_previous_lvl.classList.add('previousLvl');

    // Check if this is a top level nav, and if so, hide back button
    if (this.arr_nav_levels.length == 0) {
      this.hideNavBackBtn();
    }

    // Set the title
    this.setNavHeaderTitleFromNav(node_previous_lvl);

    // Populate nav node
    this.populateNav(node_previous_lvl);

    setTimeout(() => {
      // Move levels up
      this.moveNavLevels('up');
    },10);
  }

}