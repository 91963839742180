export default class FormDefaultHandling {

  // This receives a node that we can use for our manipulation
  constructor(form) {
    this.form = $(form);
    this.init();
  }

  init() {
    this.selectFormLoaderMask();
  }

  selectFormLoaderMask() {
    this.loaderMask = $(this.form).prev('div.loader-mask');
  }

  showLoaderMask() {
    if(typeof this.loaderMask !== "undefined") {
      this.loaderMask.fadeIn(300);
    }
  }

  hideLoaderMask() {
    if(typeof this.loaderMask !== "undefined") {
      this.loaderMask.fadeOut(300);
    }
  }

  buildSubmitData(form) {
    let formData = new FormData(),
        fields = form.querySelectorAll('input, textarea, select');

    for (var fieldIndex in fields) {
      let field = fields[fieldIndex];
      if (field.nodeType == 1 && field.value !== "" && field.name !== "") {
        if (field.type == "radio") {
          if (field.checked) {
            formData.append(field.name, field.value);
          }
        }
        else if (field.type == "select") {
          
        }
        else if (field.type == "checkbox") {
          
        }
        else if (field.type == "file") {
          if (field.files.length > 0) {
            formData.append(field.name, field.files[0], field.files[0].name);            
          }
        }
        else {
          formData.append(field.name, field.value);
        }   
        
      }
    }

    return formData;

  }

  ajaxSubmitAction(custom_ajax_options = null) {
    let default_ajax_options = {
      url: $(this.form).attr('action'),
      type: $(this.form).attr('method'),
      dataType: 'json',
      cache: false,
      contentType: false,
      processData: false,
      async: false,
      data: null,
      beforeSend: (xhr, settings) => { 
        this.showLoaderMask();
        settings.data = this.buildSubmitData($(this.form)[0]);
      },
      statusCode: {
          200 : () => { 
            this.hideLoaderMask();
          },
          400 : () => { 
            this.hideLoaderMask();
          },
          500 : () => { 
            this.hideLoaderMask();
          }
      }
    }

    if(custom_ajax_options) {
      Object.assign(default_ajax_options, custom_ajax_options);
    }

    return () => {
      $.ajax(default_ajax_options);
    }
  }

}