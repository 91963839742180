export default class FormValidator {

  /*
   * Construct method must receive parameters for validation
   */
  constructor(params) {

    // Attempt to process parameters
    if(this.processParams(params)) {

      this.addValidation();
      this.formSubmitBtn = this.targetForm.querySelector('[type="submit"]');
      this.formSubmitBtn.disabled = true;
      this.setSubmitAction();
      if (this.validateOnInit === true) {
        this.runValidation();
      }
    } else {
      // Unable to process parameters
      console.error("FormValidator was unable to process parameters passed.");
      return null;
    }

  }

  /*
   * Method processes parameters passed to the method
   * param must be a type of object
   */
  processParams(params) {

    // First check if params is a type of object
    if(typeof params == "object") {

      let self = this;

      // Check if we have form selected
      if(typeof params.form == 'object' && params.form.nodeType === 1) {

        // This is selectable node. Assign it to the object varaibles
        // TODO

      } else if(typeof params.formSelector == 'string') {

        // We have a form selector. Attempt to select it
        this.targetForm = document.querySelector(params.formSelector);

        if(this.targetForm !== null) {
          this.requiredFields = {};
          Object.keys(params.requiredFields).forEach(function(key){
            self.requiredFields[key] = {
              field : self.targetForm.querySelector(params.requiredFields[key].selector),
              regex : params.requiredFields[key].regex,
              valid : false
            }
          });

          // Assign callback action
          this.submitAction = params.submitAction || false;
          this.ajaxed = params.ajaxed || false;
          if (typeof params.validateOnInit == 'boolean') {
            this.validateOnInit = params.validateOnInit;
          } else {
            this.validateOnInit = true; 
          }
          return true;

        } else {
          console.error(`Unable to find form ${params.formSelector}`);
          return null;
        }

      } else {
        console.error("params.form must contain selected form node. Got " + typeof params.form);
        return null;
      }


    } else {
      // Incorrect data received.
      console.error("FormValidator must receive custom parameters");
      return null;
    }
  } 



  /*
   * This method adds validation to required and optional fields
   */
  addValidation() {
    this.addValidationToRequiredFields();
    this.addValidationToOptionalFields();
  }

  /*
   * Method adds validation to required fields
   */
  addValidationToRequiredFields() {
    let self = this;
    Object.keys(this.requiredFields).forEach(function(key){
      self.requiredFields[key].field.addEventListener('keyup', function(){ self.validateRequiredField(self.requiredFields[key]); });
      self.requiredFields[key].field.addEventListener('paste', function(){ self.validateRequiredField(self.requiredFields[key]); });
      self.requiredFields[key].field.addEventListener('change', function(){ self.validateRequiredField(self.requiredFields[key]); });
    });
  }

  /*
   * Method adds validation to optional fields, if there are any
   */
  addValidationToOptionalFields() {

  }


  /**
   * This method runs by comparing fields and their values based on parameters
   */
  runValidation() {
    // For each of the required field, check if they all contain valid information
    let self = this;
    for(var key in self.requiredFields) {
      self.validateRequiredField(self.requiredFields[key]);
    }
  }

  setSubmitAction() {
    let self = this;
    this.formSubmitBtn.addEventListener('click', function(ev){
      if(self.ajaxed) { 
        ev.preventDefault();
        self.submitAction();
      }
    });      
  }





  validateRequiredField(element) {
    if(element.regex.test(element.field.value)) {
      element.field.classList.remove('invalid');
      element.field.classList.add('valid');
      element.valid = true;
    } else {
      element.field.classList.remove('valid');
      element.field.classList.add('invalid');
      element.valid = false;
    }

    // Check if submit button should be enabled
    this.updateSubmitBtnStatus();
  }

  isFormSubmitable() {
    var submitable = true;
    let self = this;
    Object.keys(this.requiredFields).forEach(function(key){
      if(!self.requiredFields[key].valid) {
        submitable = false;
        return true;
      }
    });
    return submitable;
  }

  updateSubmitBtnStatus() {
    if(this.isFormSubmitable()) {
      this.formSubmitBtn.disabled = false;
    } else {
      this.formSubmitBtn.disabled = true;
    }
  }

}
