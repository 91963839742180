export default class ClubPage {
  
  constructor() {
    this._sectionSelector = 'section.club-info-section';
    if ($(this._sectionSelector).length == 0) {
      return;
    }
    this._maskSelector = '#full-page-mask';
    this._memberInfoSelector = '#club-member-info'; 
    this.init();
  }

  init() {
    this.bindClubMemberEvents();
    this.bindCloseClubMemberInfoEvents();
  }

  bindCloseClubMemberInfoEvents() {
    $('body').on('click', '.close-member-info, #full-page-mask', (e) => {
      this.hideFullPageMask();
      this.hideClubMemberInfo();
      //$('body').removeClass('modal-open');
    });
  }

  bindClubMemberEvents() {
    let self = this;
    $('body').on('click', '.member-container', (e) => {
      e.preventDefault();
      //$('body').addClass('modal-open');
      let _clickedMemberContainer = $(e.currentTarget);
      let _intMemberId = $(_clickedMemberContainer).find('.member').data('memberId');
      if (_intMemberId) {
        $.ajax({
          method: 'GET',
          url: `/api/pl-en/club-members/${_intMemberId}`,
          success: (response) => {
            self.showFullPageMask();
            let responseData = JSON.parse(response);
            self.populateClubMemberInfo(responseData.data);
            self.showClubMemberInfo();
          },
          error: (response) => {

          }
        });
      }
    });
  }

  showFullPageMask() {
    $(this._maskSelector).addClass('show');
  }

  hideFullPageMask() {
    $(this._maskSelector).removeClass('show');
  }

  populateClubMemberInfo(data) {
    let clubMemberDetails = $(this._memberInfoSelector);
    // Avatar
    let avatarUrl = '/img/club_member_placeholder.png';
    if (data.thumbnail_url) {
      avatarUrl = data.thumbnail_url
    }
    $('.member-avatar', clubMemberDetails).css({
      backgroundImage: `url(${avatarUrl})`
    });

    // Nickname, type, description
    $('.nickname', clubMemberDetails).html(data.nickname);
    $('.type', clubMemberDetails).html(data.type);
    $('.description', clubMemberDetails).html(data.description);
  }

  showClubMemberInfo() {
    $(this._memberInfoSelector).addClass('show');
  }

  hideClubMemberInfo() {
    $(this._memberInfoSelector).removeClass('show');
  }
}