
class HomePage {
  
  constructor() {
    this._carouselSelector = ".carousel-container";
    this._carouselContainer = $(".carousel-container");
    this._carouselTime = 4000;
    this._slides = $('.slide', this._carouselContainer);
    this._slidesCnt = this._slides.length;
  }

  init() {
    this.showCarouseLoader();
    this.loadCarouselImages();
  }

  showCarouseLoader() {
    this._carouselContainer.addClass('loading');

  }

  hideCarouselLoader() {
    this._carouselContainer.removeClass('loading');      
  }

  loadCarouselImages() {
    let _slidesLoaded = 0;
    $(this._slides).each((i, slide) => {
      let _img = $('<img>').attr('src', $(slide).data('background'));
      $(slide).prepend(_img);
      $(_img).on('load', () => {
        $(slide).css({
          backgroundImage: `url(${$(slide).data('background')})`
        });
        _slidesLoaded++;
        if (_slidesLoaded == this._slidesCnt) {       
          this.hideCarouselLoader();
          this.bindCarouselEvents();
        }  
      });
    });
  }

  bindCarouselEvents() {

    // Get first slide
    let _currentSlide = this._slides.first();
    _currentSlide.fadeIn(800).addClass('current');

    setInterval(() => {
      // Select next slide
      let _nextSlide;
      if (_currentSlide.next('div.slide').length == 0) {
          _nextSlide = this._slides.first();
      } else {
          _nextSlide = _currentSlide.next('div.slide');
      }

      _nextSlide.addClass('next').fadeIn(800, () => {
          _currentSlide.removeClass('current').hide();
          _currentSlide = _nextSlide;
          _currentSlide.removeClass('next').addClass('current');
      });
    }, this._carouselTime);

  }

}

module.exports = HomePage;