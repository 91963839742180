import MJSSideNavBar from './modules/mjsSideNavBar.js';
import HomePage from './pages/home';
import ContactPage from './pages/contact';
import ClubPage from './pages/club';

$(() => {

  const _mjsSideBar = new MJSSideNavBar();
  _mjsSideBar.init();

  $('body').on('click', '#navTrigger', () => {
    _mjsSideBar.show();
  });

  if ($('main.page-home').length > 0) {
    const _homePage = new HomePage();
    _homePage.init();
  }

  if ($('main.contact.page').length > 0) {
    const _contactPage = new ContactPage();
    _contactPage.init();
  }

  if ($('main.club.page').length > 0) {
    const clubPage = new ClubPage();
  }
  
});